import React from "react";
import { Squeak } from "squeak-react";
import Layout from "../components/Layout";
import Section from "../components/Section";

const Support = () => {
  return (
    <Layout>
      <article className="flex flex-col items-start px-5 pt-2">
        <Section id="questions">
          <h2 className="text-red">Ask a question (Demo!)</h2>
          <h3>
            Here's a live version of Squeak!, specific to answering questions
            about Squeak! (It's so meta.)
          </h3>

          <Squeak
            apiHost="//squeak-cloud-2cfei.ondigitalocean.app"
            apiKey="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB4aXBrcXV2d3FhYXVudXpqb2dlIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDk3MjE3ODUsImV4cCI6MTk2NTI5Nzc4NX0.SxdOpxHjVwap7sDUptK2TFJl7WK3v3HLuKbzb0JKeKg"
            url="https://pxipkquvwqaaunuzjoge.supabase.co"
            organizationId="bc92bb89-8590-45b9-99ff-f5a5a8faa080"
          />
        </Section>
      </article>
    </Layout>
  );
};

export default Support;
